<template>
  <navbar class="" style="background-color: #22326E;"/>
  <div style="width:  100%;max-width: 960px; align-items: center;margin: auto">
    <div class="dashboard" >

      <h2>Bienvenido {{$store.state.first_name || $store.state.email}}!</h2>
      <h3>Este es tu Dashboard</h3>
    </div>
    <div class="" style="text-align: center; font-size: 1.5rem">
      <p>¡Sigue la trayectoria de tu carga en cualquier momento y en cualquier lugar!</p>
    </div>
    <Mapbox  ref="maibo" :myitems="items" />

  </div>
<!--      <Footer />-->
</template>

<script>
import axios from "axios";
import Mapbox from "./Mapbox"
// import Footer from "@/views/Footer.vue";
import Navbar from "@/views/Navbar.vue";

export default {
  name: 'Dashboard',
  components: {
    Navbar,
    Mapbox,
    // Footer
  },
  data() {
    return {
      items: [],
      time: null
    }
  },
  created() {
    this.getManifiestosCliente()
  },
  methods: {
    getManifiestosCliente: function() {
      let self = this
      self.items = []
      let token = localStorage.getItem('token');
      axios.get('/operaciones/manifiestosUsuario/', {
      headers: { 'Authorization': 'Token ' + token}
      })
          .then(response => {
            if(response.status === 200) {
              console.log(response.data);
              // eslint-disable-next-line

              response.data.forEach(function(item){
                // console.log(item.fields)
                // console.log(self.items)
                self.items.push(item)
                // your logic
              });
              console.log(self.items)
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    getTime: function() {
      axios.get('http://127.0.0.1:8000/login/get-time/')
            .then(response => {
        if(response.status === 200) { 
          console.log("then here: ", response);
          this.time = response.data.current_time;
        }
        })
        .catch(error => {
          console.log(error)
        })
    },
    dummyRequest: function() {
      axios.get('http://127.0.0.1:8000/login/dummy-request/')
        .then(response => {
        if(response.status === 200) { 
          console.log("then here: ", response);
          this.time = response.data.current_time;
        }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getBeers: async function() {
      const beersData = await axios.get(
        'https://ih-beers-api2.herokuapp.com/beers'
      );
        console.log(beersData.data);
        this.items = beersData.data;
    },
    singleBeer: async function(id) {
      const beerData = await axios.get(
        'https://ih-beers-api2.herokuapp.com/beers/' + id
      );
        console.log(beerData.data);
        this.items = [beerData.data];
    }
  }
}
</script>
<style lang="scss">
.dashboard {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.getter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.textos {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: left;
  // justify-content: space-evenly;
  // align-items: left;
}
p {
  padding: 0.5rem;
}
.button {
  padding: 0 1rem;
}
.beers {
  padding: 1rem;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: 20% 20% 20% 20% 20%;
}
ul {
  list-style-type: none;
  padding: 1rem;
}
li {
  text-align: left;
}
.beer-images {
  width: 5rem;
  display: flex;
}

</style>