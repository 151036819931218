<template>
  <div id="myModal" class="modal" style="display: flex" v-show="showModal">

    <!-- Modal content -->
    <div class="modal-content" style="text-align: right">
      <div style="text-align: right">
        <span class="close" style=";padding: 0.5rem; font-size: 2.5rem" @click="showModal=null">&times;</span>
      </div>
    <div>
      <div style="text-align: left; padding-left: 1rem">
        MRN: {{mrn}}
      </div>
      <div style="text-align: left; padding-left: 1rem">
        Origen: {{puertoOrigen}}
      </div>
      <div style="text-align: left; padding-left: 1rem">
        Destino: {{puertoDestino}}
      </div>
      <div class="map">
        <div id="map" style="width: 100%;">
          <div v-show="loadingData" style="position: absolute;   width: 100%;
  height: 100%;
  z-index: 3;
  top: 0;
  left: 0; background: #1ed760;
  display: flex;
  flex-direction: column;
align-items: center;
justify-content: center;


"> <div v-show="!errorLoadindData">...  {{stateMessage}} ... </div>
            <div v-show="errorLoadindData" style="z-index: 1000; text-align: center">

              <div>Error cargando ubicación</div>
              <button @click="locate(this.manifiesto_id, this.mrn, this.puertoOrigen, this.puertoDestino )"> REINTENTAR </button>
            </div>
          </div>
        </div>


      </div>
      <div style="text-align: center">
        <button @click="showModal=null" type="button" class="btn btn-lg d-btn "
                style="background-color: rgb(231, 61, 62) !important;width: 8rem;margin-bottom: 1rem">Cerrar
        </button>

      </div>

    </div>


    </div>
  </div>


  <div>
    Todas las fechas son GMT-5
  </div>
<!--  {{table.rows}}-->

  <div style="margin: auto; text-align: center">
    <div class="table"
         style="overflow: scroll;margin: 1rem;width: unset !important;display: block ruby; justify-content: center">
<!--{{table}}-->
<!--**       <table-lite-->
<!--        :is-static-mode="true"-->
<!--        :columns="table.columns"-->
<!--        :rows="table.rows"-->
<!--        :sortable="table.sortable"-->
<!--    ></table-lite>-->
<!--      **-->

      <div>
      </div>
      <table class="" style="text-align: left">
        <thead>
        <tr>
          <th> SHIPPER</th>
          <th> HBL</th>
          <th> MBL</th>
          <th> NAVIERA</th>
          <th> Port of Departure</th>
          <th> Port of Arrival</th>
          <th> Departure</th>
          <th> Arrival</th>
          <th> Acciones</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(cargo) in paginated" :key=cargo.id>
          <td>{{ cargo.shipper.toUpperCase() }}</td>
          <td>{{ cargo.hblHawb.toUpperCase() }}</td>
          <td>{{ cargo.mblMawb.toUpperCase() }}</td>
          <td>{{ cargo.naviera.toUpperCase() }}</td>
          <td>{{ cargo.puertoEmbarque.toUpperCase() }}</td>
          <td>{{ cargo.puertoDestino.toUpperCase() }}</td>
          <td>{{ cargo.ETD.toUpperCase() }}</td>
          <td>{{ cargo.eta.toUpperCase() }}</td>
          <td>

            <button v-if="cargo.manifiesto_id" class="d-btn d-btn--primary" @click="locate(cargo.manifiesto_id, cargo.mrn, cargo.puertoEmbarque, cargo.puertoDestino )">Ver</button>

            <button v-if="!cargo.manifiesto_id" disabled class="d-btn d-btn--primary" style="background: white !important;box-shadow: none; cursor: default" @click="locate(cargo.manifiesto_id, cargo.mrn, cargo.puertoEmbarque, cargo.puertoDestino )">Ver</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
<!--  {{myitems.length }}-->
<!--  {{myitems.length  /10}}-->


  <nav aria-label="Page navigation example">
    <v-pagination style="justify-content: center; font-size: 3rem !important;"
        v-model="page"
        :pages="Math.ceil((myitems.length  /pageSize))"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="updateHandler"
    />
    <!--    <ul class="pagination justify-content-center">-->
<!--      <li class="page-item disabled">-->
<!--        <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>-->
<!--      </li>-->
<!--      <li class="page-item disabled"><a class="page-link" href="#">1</a></li>-->
<!--      <li class="page-item"><a class="page-link" href="#">2</a></li>-->
<!--      <li class="page-item"><a class="page-link" href="#">3</a></li>-->
<!--      <li class="page-item">-->
<!--        <a class="page-link" href="#">Next</a>-->
<!--      </li>-->
<!--    </ul>-->
  </nav>
</template>

<script>
import mapboxgl from "mapbox-gl";
import TableLite from 'vue3-table-lite'


import "mapbox-gl/dist/mapbox-gl.css";
import {onMounted} from "vue";
import axios from "axios";

let selected = {
  countryOfDeparture: null,
  countryOfArrival: null,
  vesselLocationMarker: null
};


import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
let map = null;
export default {

  props: ["myitems",],

  components : {
    VPagination,
    // eslint-disable-next-line
    TableLite,
  },
  created() {
  },
  setup() {
    onMounted(() => {
      mapboxgl.accessToken =
          "pk.eyJ1IjoidGltYm90aW1iZXIiLCJhIjoiY2s2Z2s1aTU0MHltMzNrcXB3bjlnYWNyYiJ9.14z3LvxL-5ovU8Ur6CuJtw";
      map = new mapboxgl.Map({
        trackResize: true,
        container: "map",
        style: "mapbox://styles/mapbox/navigation-night-v1",
        center: [0, 0],
        doubleClickZoom: true,
        zoom: 1,
      });

      map.on('dataloading', () => {
        window.dispatchEvent(new Event('resize'));
        // mapboxObj.resize(); also work
      })
      class PitchToggle {
        constructor({bearing = -20, pitch = 70, minpitchzoom = null}) {
          this._bearing = bearing;
          this._pitch = pitch;
          this._minpitchzoom = minpitchzoom;
        }

        onAdd(map) {
          this._map = map;
          let _this = this;

          this._btn = document.createElement("button");
          this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
          this._btn.type = "button";
          this._btn["aria-label"] = "Toggle Pitch";
          this._btn.onclick = function () {
            if (map.getPitch() === 0) {
              let options = {pitch: _this._pitch, bearing: _this._bearing};
              if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
                options.zoom = _this._minpitchzoom;
              }
              map.easeTo(options);
              _this._btn.className =
                  "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
            } else {
              map.easeTo({pitch: 0, bearing: 0});
              _this._btn.className =
                  "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
            }
          };

          this._container = document.createElement("div");
          this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
          this._container.appendChild(this._btn);

          return this._container;
        }

        onRemove() {
          this._container.parentNode.removeChild(this._container);
          this._map = undefined;
        }
      }

      map.addControl(new mapboxgl.NavigationControl(), "top-left");
      map.addControl(new PitchToggle({minpitchzoom: 11}), "top-left");
    });
  },
  computed: {
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      const a = this.myitems.slice(this.indexStart, this.indexEnd)
      if (a.length < this.pageSize){
        a.push({'mrn':'', 'shipper': '',
          'hblHawb' : '', 'mblMawb' : '', 'naviera': '',
          'puertoEmbarque':'',puertoDestino:'',ETD:'', eta:''})
      }
      return a;
    }
  },
  data() {

    return {
      page: 1,
      pageSize:5,
      current:1,

      manifiesto_id: null,
      mrn: null,
      puertoOrigen: null,
      puertoDestino: null,
      loadingData : true,
      stateMessage : 'consultando',
      errorLoadindData : false,

      showModal: false,
      exampleCargos: [
        {
          departureDate: '10.03.2021 - 18:30',
          arrivalDate: '20.03.2021 - 18:30',
          countryOfDeparture: "usa",
          countryOfArrival: "ecu",
          cargoLocation: [-85, 0],
          id: 1
        },
        {
          departureDate: '01.03.2021 - 10:00',
          arrivalDate: '03.03.2021 - 10:00',
          countryOfDeparture: "mex",
          countryOfArrival: "bra",
          cargoLocation: [-70, 25.869997],
          id: 2
        },
        {
          departureDate: '29.03.2021 - 20:30',
          arrivalDate: '15.04.2021 - 20:30',
          countryOfDeparture: "esp",
          countryOfArrival: "gbr",
          cargoLocation: [-9.560016, 48.152492],
          id: 3
        },
        {
          departureDate: '24.04.2021 - 20:30',
          arrivalDate: '30.04.2021 - 20:30',
          countryOfDeparture: "arg",
          countryOfArrival: "ind",
          cargoLocation: [0, 0],
          id: 4
        }
      ],
    }
  },
  methods: {
    updateHandler: function (el){
      console.log(el)
      this.current = el
      // return this.alphabets.slice(this.indexStart, this.indexEnd);

    },
    locate: function (manifiesto_id, mrn, puertoOrigen, puertoDestino) {
      // map.resize()

      this.mrn = mrn
      this.puertoOrigen = puertoOrigen
      this.puertoDestino = puertoDestino
      this.manifiesto_id = manifiesto_id

      this.showModal = true

      console.log(manifiesto_id)
      let self = this
      self.items = []
      let token = localStorage.getItem('token');
      this.errorLoadindData  = false;
      self.loadingData = true

      axios.get('/operaciones/manifiestoLonLat/?manifiesto_id=' + manifiesto_id, {
        headers: {'Authorization': 'Token ' + token}
      })
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              self.errorLoadindData = false
              self.loadingData = false
              console.log('response ok');
              console.log(response.data);
              map.flyTo({
                center: [response.data.lon, response.data.lat],
                speed: 0.2,
                zoom: 2
              });
              self.removeSelected();
              self.addSelected(response.data.mapbox_origin, response.data.mapbox_destiny, [response.data.lon, response.data.lat]);
              // eslint-disable-next-line

              // response.data.forEach(function(item){
              //   // console.log(item.fields)
              //   // console.log(self.items)
              //   self.items.push(item)
              //   // your logic
              // });
              // console.log(self.items)
            }else{
              self.loadingData = true
              self.errorLoadindData = true
            }
          })
          .catch(error => {
            self.loadingData = true
            self.errorLoadindData = true
            console.log('error')
            console.log(error)

          })
      console.log(self.errorLoadindData)
      console.log('this.loadingData')
      console.log(this.loadingData)
      console.log(this.errorLoadindData)


      // map.flyTo({
      //   center: this.exampleCargos[e].cargoLocation,
      //   speed: 0.2,
      //   zoom: 2
      // });
      // this.removeSelected();
      // this.addSelected(e);
    },
    removeSelected: function () {
      if (selected.countryOfDeparture) {
        map.removeLayer(selected.countryOfDeparture + "-fill");
        map.removeSource(selected.countryOfDeparture);
        selected.countryOfDeparture = null;
        map.removeLayer(selected.countryOfArrival + "-fill");
        map.removeSource(selected.countryOfArrival);
        selected.countryOfArrival = null;
        selected.vesselLocationMarker.remove()
        selected.vesselLocationMarker = null;
      }
    },
    addSelected: function (countryOfDeparture, countryOfArrival, cargoLocation) {
      selected.countryOfDeparture = countryOfDeparture;
      selected.countryOfArrival = countryOfArrival;

      map.addSource(countryOfDeparture, {
        type: "geojson",
        data:
            `https://raw.githubusercontent.com/johan/world.geo.json/master/countries/${countryOfDeparture.toUpperCase()}.geo.json`,
      });
      map.addSource(countryOfArrival, {
        type: "geojson",
        data:
            `https://raw.githubusercontent.com/johan/world.geo.json/master/countries/${countryOfArrival.toUpperCase()}.geo.json`,
      });
      map.addLayer({
        id: countryOfDeparture + "-fill",
        type: "fill",
        source: countryOfDeparture,
        paint: {
          "fill-color": "red",
        },
      });
      map.addLayer({
        id: countryOfArrival + "-fill",
        type: "fill",
        source: countryOfArrival,
        paint: {
          "fill-color": "blue",
        },
      });
      // map.on("click", this.exampleCargos[e].countryOfDeparture + "-fill", event => {
      //   new mapboxgl.Popup()
      //       .setLngLat(event.lngLat)
      //       .setHTML(this.exampleCargos[e].departureDate)
      //       .addTo(map);
      // });
      // map.on("click", this.exampleCargos[e].countryOfArrival + "-fill", event => {
      //   new mapboxgl.Popup()
      //       .setLngLat(event.lngLat)
      //       .setHTML(this.exampleCargos[e].arrivalDate)
      //       .addTo(map);
      // });
      let el = document.createElement('div');
      el.className = 'marker';
      let marker = new mapboxgl.Marker(el);
      marker.setLngLat(cargoLocation);
      marker.addTo(map);
      selected.vesselLocationMarker = marker
    },
  },
};
</script>

<style lang="scss">
#map {
  height: 70vh;
  width: 100vw;
}

.map {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

//
.mapboxgl-marker {
  background-image: url('~@/assets/bote.jpeg');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.marker {
  background-image: url('~@/assets/bote.jpeg');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

//
//.cargos {
//  display: flex;
//  justify-content: space-evenly;
//}
//
//.table-info {
//  display: flex;
//  justify-content: space-between;
//}

//
//table {
//  border-collapse: collapse;
//}
//
//.table {
//  display: flex;
//  justify-content: space-evenly;
//}
//
//td {
//  border-top: 1px solid #dee2e6;
//}
//
//th, td {
//  padding: .5rem;
//}
//
//.mapboxgl-ctrl-pitchtoggle-3d {
//  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjNEPC90ZXh0Pjwvc3ZnPg==);
//}
//
//.mapboxgl-ctrl-pitchtoggle-2d {
//  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjJEPC90ZXh0Pjwvc3ZnPg==);
//}

/* The Modal (background) */
.modal {
  max-width: 100%;
  max-height: 100%;
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: auto; /* 15% from the top and centered */
  //padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


/*
** Breakpoints
*/
/*
** Black
*/
/*
** Grey
*/
/*
** Colors
*/
/*
** Font
*/
.Pagination {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none; }

.PaginationControl {
  display: flex;
  align-items: center; }

.Control {
  position: relative;
  display: block;
  width: 36px;
  height: 36px;
  margin: 0 4px;
  fill: #BBBBBB; }
.Control-active {
  fill: #333333;
  cursor: pointer;
  transition: fill 0.2s ease-in-out; }
.Control-active:hover {
  fill: #000000;
  transition: fill 0.2s ease-in-out; }

/*
** Breakpoints
*/
/*
** Black
*/
/*
** Grey
*/
/*
** Colors
*/
/*
** Font
*/
.Page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin: 0 4px;
  color: #666666;
  background-color: transparent;
  font-size: 14px;
  border-radius: 3px;
  box-sizing: border-box;
  border-color: transparent;
  cursor: pointer;
  outline: 0;
  user-select: none; }
.Page:hover {
  border: 1px solid #DEDEDE; }
.Page-active {
  color: #333333;
  border: 1px solid #DEDEDE; }

.DotsHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 4px;
  margin: 0 4px;
  box-sizing: border-box; }

.Dots {
  width: 16px;
  height: 8px;
  fill: #BBBBBB; }


</style>