<template>
<h1>Tracking</h1>
    <div style="text-align: center">
        <div style="display: flex; background-color: green; height: 15rem; flex-wrap: wrap; align-items: center;
        justify-content: space-evenly; max-width: 1060px;">
            <div style="height: 9rem; background-color: red; padding: 0.5rem; margin: 0.5rem; min-width: 18rem;">
            </div>
            <div style="background-color: blue; color: white; min-height: 9rem; padding: 0.5rem; margin: 0.5rem; min-width: 18rem;">
            </div>
            <div style="height: 12rem; background-color: yellow padding: 0.5rem; margin: 0.5rem; min-width: 18rem">
            </div>
        </div>
        <form method="POST" action="">
            <input type="text" name=""/>
            <button type="submit">Submit</button>
        </form>
    </div>
<!-- crear dos columnas para el modal -->
<!-- cambiar la imagen del hamburger menu -->
<!-- float right del menu -->

</template>
