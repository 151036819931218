import axios from "axios";

export default {
  created() {
    axios.interceptors.request.use((config) => {
      if (this.$store.getters.isAuthenticated) {
        // config.headers["Authorization"] = `Token ${localStorage.getItem(
        //   "token"
        // )}`;
      } else {
        delete config.headers["Authorization"];
      }
      return config;
    });
  },
};
