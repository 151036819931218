import { createApp } from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

const app = createApp();
app.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],

  computed: {},

  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    email: (state) => state.email,
    first_name: (state) => state.first_name,
  },
  mutations: {
    UPDATE_IS_AUTHENTICATED(state, boolean) {
      state.isAuthenticated = boolean;
    },
    UPDATE_EMAIL(state, string) {
      state.email = string;
    },
    UPDATE_FIRST_NAME(state, string) {
      state.first_name = string;
    },
  },
  actions: {},
  state: {
    isAuthenticated: false,
    email: "email@example.com",
    first_name: "name goes here",
  },
});
