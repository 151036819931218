<template>
      <div class="" style="">
      <div style="width:  100%;max-width: 960px; align-items: center;margin: auto">
          <div style="">
            <img class="" style="" :src="require(&quot;@/assets/LogoHA.png&quot;)" />
          </div>
<!--          <div>-->
<!--            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">-->
<!--              <span class="navbar-toggler-icon"></span>-->
<!--            </button>-->
<!--            <div class="collapse navbar-collapse" id="navbarSupportedContent" style="display: none;">-->
<!--              <ul class="navbar-nav ml-auto">-->
<!--                <li class="nav-item" data-toggle="modal" data-target="#loginModal">-->
<!--                  <a class="nav-link" target="_self" href="#">Ingresa <span class="sr-only">(current)</span></a>-->
<!--                </li>-->
<!--                &lt;!&ndash;        <li class="nav-item" data-toggle="modal" data-target="#exampleModal" @click="login">&ndash;&gt;-->
<!--                &lt;!&ndash;          <a class="nav-link" target="_self" href="#">Registrate <span class="sr-only">(current)</span></a>&ndash;&gt;-->
<!--                &lt;!&ndash;        </li>&ndash;&gt;-->
<!--                <li class="nav-item">-->
<!--                  <a class="nav-link" target="_self" href="/terminos-y-condiciones">Términos</a>-->
<!--                </li>-->
<!--                <li class="nav-item">-->
<!--                  <a class="nav-link" target="_self" href="/faqs">FAQs</a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->

<!--          </div>-->

      </div>

	</div>
</template>

<style lang="scss">

</style>