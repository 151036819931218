import { createRouter, createWebHistory } from "vue-router";
import Convocatoria from "../views/Convocatoria.vue";
import Tracking from "../views/Tracking.vue";
import Dashboard from "../views/Dashboard.vue";
import Signin from "../views/Signin.vue";
import newConvocatoria from "../views/newConvocatoria.vue";

const routes = [
  {
    path: "/",
    name: "Convocatoria",
    component: Convocatoria,
  },
  {
    path: "/about",
    name: "newConvocatoria",
    component: newConvocatoria,
  },
  {
    path: "/tracking",
    name: "Tracking",
    component: Tracking,
  },
  {
    path: "/signin",
    name: "signin",
    component: Signin,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
