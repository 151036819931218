<template>
<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">H.A. Cargo</h5>
      </div>
      <div class="modal-body">
        <!-- ... -->
        <div tabindex="-1" role="document" class="d-flex justify-content-center" aria-labelledby="__BVID__7____BV_modal_header_" aria-describedby="__BVID__7____BV_modal_body_">
            <header class="modal-header" id="__BVID__7____BV_modal_header_">
                <h5 class="modal-title">H.A. Cargo</h5>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
                
            </header>
            <div class="modal-body" id="__BVID__7____BV_modal_body_">
                <div class="d-block text-center">
                    <div class="my-5 col-12">
                        <div class="row justify-content-center">
                            <p class="font-weight-bold">Regístrate o ingresa con tu red social favorita</p>
                            </div> 
                            <div class="row justify-content-center">
                                <div class="col-lg-5 col-12"> <!-- col-sm-10 -->
                                <button @click="preEmbarques">click here and check the console</button>
                                    <form class="signup-social">
                                        <button type="button" class="btn d-btn d-btn--facebook btn-secondary"><i class="fa fa-facebook"></i>Facebook</button> 
                                        <button type="button" class="btn d-btn d-btn--twitter btn-secondary"><i class="fa fa-twitter"></i>Twitter</button> 
                                        <button type="button" class="btn d-btn d-btn--github btn-secondary"><i class="fa fa-github"></i>Github</button> 
                                        <button type="button" class="btn d-btn d-btn--spotify btn-secondary"><i class="fa fa-spotify"></i>Spotify</button>
                                    </form>
                                </div> 
                                <div class=" col-lg-5 col-12"> <!-- col-sm-10 -->
                                    <form class="signup-social">
                                        <button type="button" class="btn d-btn d-btn--google btn-secondary"><i class="fa fa-google"></i>Google</button> 
                                        <button type="button" class="btn d-btn d-btn--instagram btn-secondary"><i class="fa fa-instagram"></i>Instagram</button> 
                                        <button type="button" class="btn d-btn d-btn--microsoft btn-secondary"><i class="fa fa-windows"></i>Microsoft</button>
                                    </form>
                                </div>
                            </div> 
                            <div class="row justify-content-center mt-4">
                                <p class="font-weight-bold">Ingresa con correo electrónico</p>
                            </div> 
                            <div>
                                <div class="row justify-content-center mt-3">
                                    <label for="email">Correo electrónico</label>
                                    <br>
                                </div> 
                                <div class="row justify-content-center">
                                    <form action="" method="POST">
                                        <p>
                                            <input id="email" name="email" type="text" placeholder="usuario@mail.com" class="d-input d-input--normal" aria-required="true" aria-invalid="false" style="width: 100% !important;">
                                            <span class="help" style="display: none;"></span>
                                            </p> 
                                        <p>
                                            <button type="submit">Send</button>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
  </div>
</div>
<!-- Layout -->
<nav class="d-footer">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-12">
                <img src="/static/img/logo.be679c5.png" class="d-footer__img">
            </div> 
            <div class="col-lg-6 col-12">
                <ul class="float-right d-footer__list nav">
                    <ul class="ml-auto navbar-nav"></ul> 
                    <li class="nav-item"><a href="#" class="nav-link" data-toggle="modal" data-target="#exampleModalCenter" target="_self">
                        Ingresa
                    </a></li>
                    <!-- <li class="nav-item"><a href="/application" class="nav-link " data-toggle="modal" data-target="#exampleModalCenter" target="_self">Ingresa</a></li>  -->
                    <li class="nav-item"><a href="/terminos-y-condiciones" class="nav-link" target="_self">Términos</a></li>
                    <li class="nav-item"><a href="/faqs" class="nav-link" target="_self">FAQs</a></li>
                </ul>
            </div>
        </div>
    </div>
</nav>

<div class="jumbotron text-center">
    
  <h1>H.A. Cargo</h1>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipisicing elit...
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris..
  </p> 
</div>
  
<div class="container">
  <div class="row">
    <div class="col-sm-4">
      <h3>Column 1</h3>
      <button type="button" class="btn btn-lg d-btn d-btn--primary btn-secondary">Duis aute</button>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
      <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>
    </div>
    <div class="col-sm-4">
      <h3>Column 2</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
      <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>
      h<div><span tabindex="0" data-link="#share-facebook"><i class="hero__sharing-buttons fa fa-facebook"></i></span> <span tabindex="0" data-link="#share-googleplus"><i class="hero__sharing-buttons fa fa-google-plus"></i></span> <span tabindex="0" data-link="#share-linkedin"><i class="hero__sharing-buttons fa fa-linkedin"></i></span> <span tabindex="0" data-link="#share-twitter"><i class="hero__sharing-buttons fa fa-twitter"></i></span></div>
    </div>
    <div class="col-sm-4">
      <h3>Column 3</h3>        
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>
      <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>
    </div>
  </div>
</div>
<footer class="d-footer">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-12">
                <img src="/static/img/logo.be679c5.png" class="d-footer__img">
            </div> 
            <div class="col-lg-6 col-12">
                <ul class="float-right d-footer__list nav">
                    <ul class="ml-auto navbar-nav"></ul> 
                    <li class="nav-item"><a href="/application" class="nav-link" target="_self">Ingresa</a></li> 
                    <li class="nav-item"><a href="/terminos-y-condiciones" class="nav-link" target="_self">Términos</a></li>
                    <li class="nav-item"><a href="/faqs" class="nav-link" target="_self">FAQs</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/axios.vue'
import axios from 'axios';

export default {
  name: 'newConvocatoria',
  components: {
  },
  data() {
    return {
			formRegister: {
				password: "",
				username: "",
				first_name: ""
			}
      }
  },
    methods: {
    preEmbarques() {
        axios.get('http://127.0.0.1:8000/admin/crm/preembarque/')
            .then(response => {
                console.log("then here: ", response);
                if(response.status === 200) {
                    localStorage.setItem('token', response.data.token)
                    localStorage.setItem('email', response.data.email)
                    localStorage.setItem('first_name', response.data.first_name)
            }
            })
            .catch(function(error) {
            console.log(error)
            })
    }
  }
}
// vue navigation bar
// añadir password y username
</script>

<style lang="scss">

</style>