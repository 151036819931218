<template>
    <div class="">
        <p class="font-weight-bold">Ingresa con correo electrónico</p>
        <label for="email">E-mail</label><br>
        <p>
          <input v-model="formLogin.username" @keyup="validateEmailForKeypress" @change="validateEmail" id="email" name="email" type="email" placeholder="usuario@mail.com" style="width: 100% !important;">
          <span class="" style="display: none;"></span></p>
        <label for="password">Password</label><br>
        <p><input type="password" v-model="formLogin.password" id="password" name="password" placeholder="Your password" class="" aria-required="true" aria-invalid="false" style="width: 100% !important;"><span class="help" style="display: none;"></span></p>
      {{msg.email}}
        <div style="text-align: center"><button
            class="btn d-btn btn-primary" data-dismiss="modal" @click="registroEmail">Ingresar</button>
          <div>
            {{msg.login}}
          </div>
        </div>
	</div>
</template>

<script>
import axios from 'axios';
import AuthMixin from "@/components/AuthMixin";

export default {
  name: 'EmailAuth',
  mixins: [AuthMixin],
  data() {
    return {
      msg: {email:null, login:null,},

			formLogin: {
				password: "",
				username: "",
			}
      }
  },
  methods: {
    validateEmailForKeypress() {
      console.log('hey')
      //eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.formLogin.username)){
        console.log('valid')
        this.msg.email = '';
        return true
      }
    },
    validateEmail() {
      console.log('hey')
      //eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.formLogin.username)){
        console.log('valid')
        this.msg.email = '';
        return true
      } else {
        console.log('invalid')
        this.msg.email = 'Ingresa un correo válido';
        return false
      }
    },
		registroEmail: function() {
      this.msg.login = null
      if (!this.validateEmail()){
        console.log('a')
        return
      }
      console.log('click')
      var $this = this
      axios.post('/login/email-login/', this.formLogin)
      // axios.post('https://sistema.hacargo.com/login/email-login/', this.formLogin)
				.then(response => {
					console.log("then here: ", response);
					if(response.status === 200) {
						this.$store.commit("UPDATE_IS_AUTHENTICATED", true);
						this.$store.commit("UPDATE_EMAIL", response.data.email);
						this.$store.commit("UPDATE_FIRST_NAME", response.data.first_name);
						localStorage.setItem('token', response.data.token)
            console.log(response.data)
						this.$router.push("/dashboard");
				}
				})
				.catch(function(error) {
          $this.msg.login = 'Error en login'
					
				console.log(error)
				})
		},
  }
}
</script>