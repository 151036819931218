import { createApp ,} from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
var baseDomain = "localhost" + ":8000";
// eslint-disable-next-line
var baseUrl = "http://" + baseDomain;
if (document.location.protocol == "https:") {
    baseUrl = "https://sistema.hacargo.com";
}
import axios from "axios";
// axios config
axios.defaults.baseURL = baseUrl;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.timeout = 90000;


const myapp = createApp(App)
    .use(store)
    // .use(axios)
  .use(router)
  .mount("#app");
console.log(myapp)
