<template>
<!-- Modal -->
<div class="landing-convocatoria">
  <div id="myModal" class="modal" style="display: flex" v-show="showModal">

    <!-- Modal content -->
    <div class="modal-content" style="text-align: right; max-width: 20rem">
      <div style="text-align: right">
        <span class="close" style=";padding: 0.5rem; font-size: 2.5rem" @click="showModal=null">&times;</span>
      </div>
      <div style="text-align: left; padding: 1rem">
          <email-auth />
      </div>


    </div>
  </div>

<!--	<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
<!--		<div class="modal-dialog" role="document">-->
<!--			<div class="modal-content">-->
<!--				<div class="modal-header">-->
<!--				<h5 class="modal-title">H.A. Cargo</h5>-->
<!--				<button type="button" aria-label="Close" class="btn btn-secondary close" data-dismiss="modal">×</button>-->
<!--				</div>-->
<!--				<div class="modal-body row justify-content-center">-->
<!--&lt;!&ndash;					<social-auth />&ndash;&gt;-->
<!--					<email-auth />-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
<!--	</div>-->


  <navbar class="" style="background-color: #22326E;"/>

  <!-- Hero-->
  <div class="hero">
<!--    <div class="hero__background"></div>-->
    <!-- Rectángulo redondeado-->
<!--    <div class="hero__shape">-->
<!--      <img :src="require(&quot;@/assets/shape.svg&quot;)"/>-->
<!--    </div>-->
    <div class="container">
      <!-- Hero copy-->
      <div class="row justify-content-between" style="justify-content: center !important;margin-top: 2rem">
        <div  class="col-lg-5  " >
          <div class="hero__description">
<!--            <h2></h2>-->
            <p class="d-lead-text">
              Le damos la bienvenida al sistema de rastreo de H.A. Cargo:
              Aquí podrá conocer la ubicación de su carga en tiempo real.
                <strong> ¡Empecemos! </strong></p>
            <a class="nav-link" target="_self" href="#">
              <span class="nav-item" data-toggle="modal" data-target="#loginModal">

              <button @click="showModal=true" type="button" class="btn btn-lg d-btn d-btn--primary btn-secondary" style="background-color: rgb(231, 61, 62) !important;">Ingresar</button>
              </span>
            </a>
            <!-- Botones sociales-->
            <div class="hero__social">
              <div style="margin: 1.5rem">
              Visita nuestras redes sociales.

              </div>
                <div>
                  <network network="facebook"><i class="hero__sharing-buttons fa fa-facebook"></i></network>
                  <network network="googleplus"><i class="hero__sharing-buttons fa fa-google-plus"></i></network>
                  <network network="linkedin"><i class="hero__sharing-buttons fa fa-linkedin"></i></network>
                  <network network="twitter"><i class="hero__sharing-buttons fa fa-twitter"></i></network>
                </div>
            </div>
            <div class="hero__social">
              <div style="margin: 1.5rem">
                Visita los siguientes links de interés.

              </div>
              <div>
                <div style="text-decoration: underline; cursor:pointer;margin-bottom: 1rem"> Preguntas Frecuentes</div>
                <div style="text-decoration: underline; cursor:pointer;"> Términos y condiciones</div>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="d-none d-lg-block col-lg-6">-->
<!--          <img class="hero__icon" :src="require(&quot;@/assets/quienes-somos1.jpeg&quot;)"/>-->
<!--        </div>-->
      </div>
    </div>
  </div>
  <!-- Background Lines -->
<!--	<super-footer />-->
</div>
</template>

<script>
// import axios from 'axios';
// import AuthMixin from "@/components/AuthMixin";
import Navbar from "@/views/Navbar.vue";
// import Footer from "@/views/Footer.vue";
// import SocialAuth from "@/components/auth/SocialAuth.vue";
import EmailAuth from "@/components/auth/EmailAuth.vue";
// import EmailRegistration from "@/components/auth/EmailRegistration.vue";

export default {
  name: 'Convocatoria',
//   mixins: [AuthMixin],
  components: {
	Navbar,
	// "super-footer": Footer,
	// EmailRegistration,
	EmailAuth
  },
  data() {
    return {
      showModal: false,
    }
  },

  methods: {
		aplicarVotacion: function(e) {
		var evento = new Event('eventoVotar')
		e.target.dispatchEvent(evento)
		},
		login() {
		//   this.$refs.modalIngreso.show()
		}
  },
  created() {

  }
}
</script>
<style lang="scss">
/* Variables */
body{

  height: 100vh;
  //width: calc(100vw - 1rem);
}

$baseFontSize : 16px;
$danger : #F67878;
$disabled : #FBFBFB;
$facebook : rgb(59, 89, 152);
$github : #333;
$grape : #2E3282;
$instagram : #0e456d;
$linkedin : #0077B5;
$lightCyan : #EEF5F9;
$lightGray : #E0E0E0;
$microsoft : #0067b8;
$primary : #05B9AA;
$redGoogle : #EA4335;
$secondary : #6D3F98;
$shadows : rgba(0, 0, 0, .1);
$spotify : #1ED760;
$success : #45F392;
$tertiary : #6977B7;
$textColor : #666;
$twitter : #0084B4;
$warning : #FFED6C;
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}
.show {
    display: block!important;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.fa-facebook-f:before, .fa-facebook:before {
    content: "\f09a";
}
#app {
	font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
	margin-top: 0;
}
.d-btn {
	border: none !important;
	border-radius: 5rem !important;
	box-shadow: 0 0.125rem 0.5rem rgba(0,0,0,0.1);
	color: #fff;
	cursor: pointer;
	font-weight: 700;
	outline: none;
	padding: 0.625rem 2rem;
	transition: all 0.2s linear;
}
.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}
.d-btn:hover {
	color: #fff;
	box-shadow: 0 0.125rem 0.5rem rgba(0,0,0,0.4);
}

.d-btn:hover .fa {
	transform: scale(1.25, 1.25);
}

.d-btn--primary {
	background-color: #05b9aa !important;
}

.d-btn--primary:hover {
	background-color: #05b9aa !important;
}

.d-btn--secondary {
	background-color: #6d3f98 !important;
}

.d-btn--secondary:hover {
	background-color: #6d3f98 !important;
}
.d-btn--tertiary {
	background-color: #6977b7 !important;
}
.d-btn--tertiary:hover {
	background-color: #6977b7 !important;
}
.d-btn--facebook {
	background-color: #3b5998 !important;
}
.d-btn--facebook:hover {
	background-color: #3b5998 !important;
}
.d-btn--twitter {
	background-color: #0084b4 !important;
}
.d-btn--twitter:hover {
	background-color: #0084b4 !important;
}
.d-btn--google {
	background-color: #ea4335 !important;
	color: #fff !important;
}
.d-btn--google:hover {
	background-color: #ea4335 !important;
	color: #fff !important;
}
.d-btn--instagram {
	background-color: #0e456d !important;
}
.d-btn--instagram:hover {
	background-color: #0e456d !important;
}
.d-btn--microsoft {
	background-color: #0067b8 !important;
	color: #fff !important;
}
.d-btn--microsoft:hover {
	background-color: #0067b8 !important;
	color: #fff !important;
}
.d-btn--spotify {
	background-color: #1ed760 !important;
	color: #fff !important;
}
.d-btn--spotify:hover {
	background-color: #1ed760 !important;
	color: #fff !important;
}
.d-btn--github {
	background-color: #333 !important;
	color: #fff !important;
}
.d-btn--github:hover {
	background-color: #333 !important;
	color: #fff !important;
}
.d-btn .fa {
	transition: all 0.2s linear;
	margin-right: 0.5rem;
}
.d-input {
	border-radius: 0.25rem;
	border: 1px solid #e0e0e0;
	box-shadow: inset 0 0.125rem 0.5rem rgba(0,0,0,0.1);
	color: $textColor;
	outline: none;
	padding: 0.375rem 1rem;
	width: 100%;
}
.d-lead-text {
	font-size: 1.25rem;
	font-weight: 300;
}
.d-lead-text strong {
	font-size: 1.25rem;
}
.landing-convocatoria .community {
	margin-bottom: 4.5rem;
	margin-top: 5rem;
	position: relative;
}
.landing-convocatoria .community__background {
	background: linear-gradient(to top, #eef5f9 0%, rgba(255,255,255,0.001) 50%);
	height: 103%;
	overflow: hidden;
	position: absolute;
	transform: skewY(-4deg);
	transform-origin: 0;
	width: 100%;
	z-index: -2;
}
@media screen and (min-width: 768px) {
	.landing-convocatoria .community__background {
		height: 106%;
	}
}
.landing-convocatoria .community__icons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 1.5rem;
	position: relative;
}
@media screen and (max-width: 768px) {
	.landing-convocatoria .community__icons {
		margin: 1rem 0;
	}
}
.landing-convocatoria .community__icons img {
	max-height: 7.5rem;
	margin: 0 auto;
	max-width: 90%;
	vertical-align: middle;
}
.landing-convocatoria .community__icons .confirmed {
	background: #45f392;
	color: #fff;
	font-size: 1rem;
	right: 0;
	padding: 0.25rem 0.75rem;
	position: absolute;
	top: 0;
}
.landing-convocatoria .community__ranking {
	margin-top: 5rem;
	max-width: 100%;
}
.landing-convocatoria .community h2 {
	margin: 5rem 0 1.5rem 0;
}
.landing-convocatoria .community h3 {
	margin-bottom: 2rem;
}
.landing-convocatoria .hero {
	color: #555;
	position: relative;
	width: 100%;
}
.landing-convocatoria .hero__background {
	background: #eef5f9;
	height: 110%;
	overflow: hidden;
	position: absolute;
	transform: skewY(-6deg);
	transform-origin: 0;
	width: 100%;
}
.landing-convocatoria .hero__description h2 {
	color: #6d3f98;
	font-weight: bold;
	margin: 1.25rem 0;
}
.landing-convocatoria .hero__description .btn {
	margin-bottom: 1.5rem;
	margin-top: 0.5rem;
}
.landing-convocatoria .hero__icon {
	margin: 0 auto;
	max-width: 87.5%;
	z-index: 0;
}
img {
    pointer-events: none;
    vertical-align: middle;
    border-style: none;
}
.landing-convocatoria .hero__navbar {
	padding-top: 1.25rem;
}
.landing-convocatoria .hero__navbar button {
	border: none;
	cursor: pointer;
	outline: none;
}
.landing-convocatoria .hero__shape {
	left: 45%;
	position: absolute;
	top: -7rem;
	width: 57rem;
}
@media screen and (max-width: 992px) {
	.landing-convocatoria .hero__shape {
		display: none;
	}
}
.landing-convocatoria .hero__sharing-buttons {
	border-radius: 0.25rem;
	box-shadow: 0 0.125rem 0.5rem rgba(0,0,0,0.1);
	color: #fff;
	cursor: pointer;
	font-size: 1.375rem;
	height: 2.5rem;
	line-height: 2.5rem;
	margin-right: 0.5rem;
	text-align: center;
	width: 2.5rem;
}
.landing-convocatoria .hero__sharing-buttons.fa-facebook {
	background: #3b5998;
}
.landing-convocatoria .hero__sharing-buttons.fa-google-plus {
	background: #ea4335;
}
.landing-convocatoria .hero__sharing-buttons.fa-linkedin {
	background: #0077b5;
}
.landing-convocatoria .hero__sharing-buttons.fa-twitter {
	background: #0084b4;
}
.landing-convocatoria .hero .nav-link {
	font-weight: 500;
}
@media (min-width: 768px) {
	.navbar-expand-md .navbar-nav .nav-link {
    //padding-right: 0.5rem;
    //padding-left: 0.5rem;
	}
}
@media screen and (max-width: 992px) {
	.landing-convocatoria .hero .nav-link {
		color: #6977b7 !important;
		text-align: center;
	}
}
@media screen and (max-width: 992px) {
	.landing-convocatoria .hero .navbar-nav {
		margin-top: 1rem;
	}
}
</style>